import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuthorizationService } from './api/authorization.service';
import { CategoriesService } from './api/categories.service';
import { CustomersService } from './api/customers.service';
import { ExpensesService } from './api/expenses.service';
import { JobsService } from './api/jobs.service';
import { ServicesService } from './api/services.service';
import { TimeEntriesService } from './api/timeEntries.service';
import { TimeOffRequestsService } from './api/timeOffRequests.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
                'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
