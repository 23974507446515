import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { checkout } from 'app/model/checkout';
import { task } from 'app/model/task';
import { proxy } from 'app/model/proxy'
import CryptoJS from 'crypto-js';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { WhopAuthService } from './auth.service';
import { AdminComponent } from 'app/modules/admin/admin/admin.component';
import { relativeTimeThreshold } from 'moment';
import { adminData } from 'app/model/api';


@Injectable({
    providedIn: 'root'
})

export class apiService {

    sss = 'Hor!z0n@dministr@t0r032820231992'
    auth = "FAF05EB8E7D74859A95A9CABE5525259"
    tasks: task[] = [];
    taskLength = 0;
    allTasks: task[] = [];
    proxy: proxy[] = [];
    checkouts: checkout[] = [];
    allCheckouts: checkout[] = [];
    tasksChanges = new Subject();
    allTasksChanges = new Subject();
    checkoutsChanges = new Subject();
    allCheckoutsChanges = new Subject();
    proxyChanges = new Subject();
    limitReached: boolean = true;
    baseURL = 'https://horizon.dreamtech.app/cloud'
    httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'text/html, application/xhtml+xml, */*',
        })
    };

    httpStringOptions: any = {
        headers: new HttpHeaders({
            'Accept': 'text/html, application/xhtml+xml, */*',
        }),
        observe: 'response',
        responseType: 'text'
    };

    encryptBody(body) {
        return CryptoJS.AES.encrypt(JSON.stringify(body), this.sss).toString();
    }

    encryptString(string) {
        return CryptoJS.AES.encrypt(string, this.sss).toString();
    }

    decryptString(string) {
        var bytes = CryptoJS.AES.decrypt(string, this.sss);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    constructor(
        private http: HttpClient,
        private authService: WhopAuthService,
        // private admin: AdminComponent
    ) {

        // if (this.authService.support) {
        //     setInterval(() => {
        //         this.getAllTasks()
        //     }, 15000);
        // }
    }


    getTasks(search, current, size) {
        var body = {
            authorization: this.auth,
            data: {
                id: this.authService.id,
                search: search,
                current: current,
                size: size
            }
        }
        return this.http.post(`${this.baseURL}/v2/accounts`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    getProducts(search, current, size) {
        var body = {
            authorization: this.auth,
            data: {
                id: this.authService.id,
                search: search,
                current: current,
                size: size
            }
        }
        return this.http.post(`${this.baseURL}/products`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    getPromos(search, current, size) {
        var body = {
            authorization: this.auth,
            data: {
                id: this.authService.id,
                search: search,
                current: current,
                size: size
            }
        }
        return this.http.post(`${this.baseURL}/promos`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    getTasks_v1() {
        var body = {
            authorization: this.auth,
            data: {
                id: this.authService.id
            }
        }
        this.http.post(`${this.baseURL}/accounts`, { body: this.encryptBody(body) }, this.httpStringOptions).subscribe(result => {
            // console.log(result);
            let tasks: task[] = JSON.parse(this.decryptString(result['body']));
            // console.log(tasks)
            this.tasks = tasks;
            if (this.authService.whopUser.user.limit == 0 || this.authService.whopUser.user.limit > tasks.length) {
                this.limitReached = false;
            } else {
                this.limitReached = true;
            }
            this.tasksChanges.next(tasks);
        })
    }

    getAdminDatas(search, type, current, size): Observable<ArrayBuffer> {
        var body = {
            authorization: this.auth,
            data: {
                search: search,
                type: type,
                current: current,
                size: size
            }
        }
        return this.http.post(`${this.baseURL}/admin/data`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    // getAdminDatas(search, type, current, size): adminData  {
    //     var body = {
    //         authorization: this.auth,
    //         data: {
    //             search: search,
    //             type: type,
    //             current: current,
    //             size: size
    //         }
    //     }
    //     return this.http.post(`${this.baseURL}/admin/data`, { body: this.encryptBody(body) }, this.httpStringOptions).subscribe(result => {
    //         console.log(result);
    //         const resp = JSON.parse(this.decryptString(result['body'])) as adminData;
    //         // return resp;
    //         switch (type) {
    //             case "account":
    //                 let tasks: task[] = resp.data;
    //                 // console.log(tasks)
    //                 this.allTasks = tasks;
    //                 this.allTasksChanges.next(tasks);

    //                 break;
    //             case "checkout":
    //                 let checkouts: checkout[] = resp.data;
    //                 // console.log(tasks)
    //                 this.allCheckouts = checkouts;
    //                 this.allCheckoutsChanges.next(checkouts);
    //                 break;
    //             case "proxy":
    //                 let proxy: proxy[] = resp.data;
    //                 // console.log(tasks)
    //                 this.proxy = proxy;
    //                 this.proxyChanges.next(proxy);
    //                 break;
    //         }
    //     })
    // }


    getProxy() {
        var body = {
            authorization: this.auth
        }
        this.http.post(`${this.baseURL}/proxy`, { body: this.encryptBody(body) }, this.httpStringOptions).subscribe(result => {
            // console.log(result);
            let proxy: proxy[] = JSON.parse(this.decryptString(result['body']));
            // console.log(proxy)
            // this.admin.proxy = proxy;
            this.proxy = proxy;
            this.proxyChanges.next(proxy);
        })
    }

    getCheckouts(search, current, size) {
        var body = {
            authorization: this.auth,
            data: {
                id: this.authService.id,
                search: search,
                current: current,
                size: size
            }
        }
        return this.http.post(`${this.baseURL}/v2/checkouts/user`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    getCheckouts_v1() {
        var body = {
            authorization: this.auth,
            data: {
                id: this.authService.id
            }
        }
        this.http.post(`${this.baseURL}/checkouts/user`, { body: this.encryptBody(body) }, this.httpStringOptions).subscribe(result => {
            // console.log(result);
            let checkouts: checkout[] = JSON.parse(this.decryptString(result['body']));
            // console.log(checkouts)
            this.checkouts = checkouts;
            this.checkoutsChanges.next(checkouts);
        })
    }

    // getAllCheckouts() {
    //     if (!this.authService.admin) {
    //         return [];
    //     }
    //     var body = {
    //         authorization: this.auth,
    //         data: {
    //             id: this.authService.id
    //         }
    //     }
    //     this.http.post(`${this.baseURL}/checkouts/all`, {body: this.encryptBody(body)}, this.httpStringOptions).subscribe(result => {
    //         // console.log(result);
    //         let checkouts: checkout[] = JSON.parse(this.decryptString(result['body']));
    //         // console.log(checkouts)
    //         this.allCheckouts = checkouts;
    //         this.allCheckoutsChanges.next(checkouts);
    //     })
    // }

    addTask(task) {
        var body = {
            authorization: this.auth,
            data: task,
            limit: this.authService.whopUser.user.limit,
            aio: this.authService.aio
        }
        return this.http.put<string>(`${this.baseURL}/accounts`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    startTask_v1(task) {
        var body = {
            authorization: this.auth,
            data: task
        }
        this.http.post<string>(`${this.baseURL}/accounts/start`, { body: this.encryptBody(body) }, this.httpStringOptions).subscribe(result => {
            // console.log(this.decryptString(result))
            // this.getTasks();
            // if (this.authService.support) {
            //     this.getAllTasks()
            // }
        })
    }

    startTask(task) {
        var body = {
            authorization: this.auth,
            data: task,
            aio: this.authService.aio
        }
        return this.http.post<string>(`${this.baseURL}/accounts/start`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    stopTask(task) {
        var body = {
            authorization: this.auth,
            data: task
        }
        return this.http.post<string>(`${this.baseURL}/accounts/stop`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    addProxy(proxy) {
        var body = {
            authorization: this.auth,
            data: proxy
        }
        return this.http.post<string>(`${this.baseURL}/proxy/add`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    deleteTask(task) {
        var body = {
            authorization: this.auth,
            data: task
        }
        return this.http.post<string>(`${this.baseURL}/accounts/delete`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    deleteProxy(proxy) {
        var body = {
            authorization: this.auth,
            data: proxy
        }
        return this.http.post<string>(`${this.baseURL}/proxy/delete`, { body: this.encryptBody(body) }, this.httpStringOptions)
    }

    // updateTask(task) {
    //     var body = {
    //         authorization: this.auth,
    //         data: task
    //     }
    //     this.http.put<string>(`${this.baseURL}/accounts`, {body: this.encryptBody(body)}, this.httpStringOptions).subscribe(result => {
    //         // console.log(this.decryptString(result))
    //         this.getTasks();
    //     })
    // }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

}