/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'task',
        title: 'Accounts',
        type: 'basic',
        icon: 'heroicons_outline:clock',
        link: '/task'
    },
    {
        id: 'stat',
        title: 'Stats',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/stat'
    },
    {
        id: 'checkout',
        title: 'Checkouts',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/checkout'
    },
];
export const adminsItem: FuseNavigationItem = {
    id: 'admin',
    title: 'Admin',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/admin'
}
export const supportItem: FuseNavigationItem = {
    id: 'support',
    title: 'Support',
    type: 'basic',
    icon: 'heroicons_outline:support',
    link: '/support'
}
export const databaseItem: FuseNavigationItem = {
    id: 'database',
    title: 'Database',
    type: 'basic',
    icon: 'mat_outline:search',
    link: '/database'
}
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
