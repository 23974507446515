import { ChangeDetectorRef, Component, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { WhopAuthService } from 'app/services/auth.service';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgModule } from '@angular/core'
import { apiService } from 'app/services/api.service';
import { task } from 'app/model/task';
import CryptoJS from 'crypto-js';
import { result } from 'lodash';
import { FuseAlertComponent, FuseAlertService } from '@fuse/components/alert';


@Component({
    selector: 'newtask',
    templateUrl: './task.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./task.component.scss']
})
export class NewTaskComponent {
    @ViewChild('ngForm') ngForm: NgForm;

    form: FormGroup;
    alert: any;
    id: string;
    email: string;
    pass: string;
    totp: string;
    proxy: string;
    webhook: string;

    status: string = "New";

    /**
     * Constructor
     */
    constructor(
        public auth: WhopAuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseAlertService: FuseAlertService,
        private api: apiService,
        private _matDialogRef: MatDialogRef<NewTaskComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: task
    ) {
        // this.api.tasksChanges.subscribe((action) => {
        //     this.buildInputs();
        //     this._changeDetectorRef.detectChanges();
        // });
        if (this.data != undefined) {
            this.status = "Edit"
        }
    }

    /**
     * Dismiss the alert via the service
     *
     * @param name
     */
    dismiss(name: string): void {
        this._fuseAlertService.dismiss(name);
    }

    /**
     * Show the alert via the service
     *
     * @param name
     */
    show(name: string): void {
        this._fuseAlertService.show(name);
    }

    buildInputs() {
        this.form = new FormGroup({});
        if (this.data != undefined) {
            // console.log(this.data)
            this.email = this.data.email
            this.pass = this.data.password
            this.totp = this.data.totp
            if (!this.data.cloud_proxy) {
                this.proxy = this.data.proxy
            }
            this.id = this.data._id
            this.webhook = this.data.webhook
        }
        this.buildFormControl('Amazon Email', this.email, true);
        this.buildFormControl('Amazon Password', this.pass, true);
        var proxyLabel = 'Proxy (Optional)'
        var proxyRequired = false
        if (this.auth.aio) {
            proxyLabel = 'Proxy'
            proxyRequired = true
        }
        this.buildFormControl(proxyLabel, this.proxy, proxyRequired);
        this.buildFormControl('TOTP (Optional)', this.totp, false);
        this.buildFormControl('Webhook (Optional)', this.webhook, false);
    }

    buildFormControl(name: string, value: string | number | boolean | Date = undefined, required: boolean) {

        var control = new FormControl();
        if (required) {
            control.setValidators([Validators.required])
        }
        control.setValue(value);
        this.form.addControl(name, control);
    }

    ngOnInit() {

        // this.api.getTasks();
        this.buildInputs();
    }

    /**
      * Clear the form
      */
    clearForm(): void {
        // Reset the form
        this.ngForm.resetForm();
    }

    /**
     * Send the form
     */
    sendForm(): void {

        this._matDialogRef.disableClose = true;

        var taskForm = this.ngForm.value;
        // console.log(newTask);

        var proxy = taskForm['Proxy (Optional)'];
        // console.log(proxy);
        var cloud_proxy = false;
        if (this.data) {
            // edit task , was cloud proxy but now user supplied, use form value
            if (proxy != "") {
                cloud_proxy = false;
            }

            // edit task, was user supplied but now cloud so assign new, pass null proxy to api
            if ((proxy == "" || proxy == undefined) && !this.data.cloud_proxy) {
                cloud_proxy = true;
            }

            // edit task, was cloud proxy and still is so use the same, use value from data
            if ((proxy == "" || proxy == undefined) && this.data.cloud_proxy) {
                proxy = this.data.proxy;
                cloud_proxy = true;
            }
            // console.log(this.data.proxy)
        } else {
            // new task, user didn't supply proxy
            if (proxy == "") {
                cloud_proxy = true
            }
        }

        // console.log(proxy)

        var username = this.auth.username;
        if (this.auth.discord_username != undefined) {
            username = this.auth.discord_username
        }

        var userid = this.auth.id;

        if (this.data) {
            if (this.data.username) {
                username = this.data.username;
            }

            if (this.data.userid) {
                userid = this.data.userid
            }
        }

        var newTask: task = {
            _id: this.id,
            email: taskForm['Amazon Email'],
            password: taskForm['Amazon Password'],
            totp: taskForm['TOTP (Optional)'],
            proxy: proxy,
            webhook: taskForm['Webhook (Optional)'],
            status: 'Idle',
            userid: userid,
            username: username,
            cloud_proxy: cloud_proxy,
            server: undefined
        }

        this.api.addTask(newTask).subscribe(res => {
            if (res) {
                // let acc: task[] = JSON.parse(this.decryptString(res['body']));
                // console.log(acc)
                this._matDialogRef.close()
            }
        });
    }

    sss = 'Hor!z0n@dministr@t0r032820231992'
    decryptString(string) {
        var bytes = CryptoJS.AES.decrypt(string, this.sss);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
