import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { whop_data } from 'app/model/whop';

@Injectable({
  providedIn: 'root'
})
export class WhopAuthService {
  isDev: Boolean = false;
  valid: Boolean = undefined;
  loginURL: string = '';
  redirectURL: string = '';
  authenticated: Boolean = false;
  admin: Boolean = false;
  support: Boolean = false;
  aio: Boolean = false;
  clientid: string = 'XM5Dw8ZZRaIEUwV8a6edpbUAhn4CCA3MEhGOUr8w-IQ';
  adminid: string = '1034225467877765210';
  avatar: string = '';
  username: string = '';
  id: string = '';
  roles: string[] = [];
  joined_at: Date;
  whopUser: whop_data;
  discord: boolean = false;
  discord_username: string = "";
  discord_id: string = "";
  display_name: string = "";

  constructor(private http: HttpClient, private _router: Router) {
    if (this.isDev) {
      this.redirectURL = 'http://localhost:4200/home'
    }
    else {
      this.redirectURL = 'https://dashboard.horizonfreebies.app/home'
    }
    this.loginURL = `https://whop.com/oauth?client_id=${this.clientid}&redirect_uri=${encodeURIComponent(this.redirectURL)}`;
  }

  async getWhopUser(code: string) {
    this.http.get<whop_data>(`https://horizon.dreamtech.app/auth?code=${code}&redir=${encodeURIComponent(this.redirectURL)}`).subscribe(result => {
      // console.log(result);
      if (result.valid) {
        // console.log('valid true')
        this.whopUser = result;
        this.valid = true;
        this.authenticated = true;
        this.id = result.user.id;
        this.username = result.user.username;
        if (result.user.username == undefined || result.user.username == "") {
          this.username = this.whopUser.user.email
        }


        this.admin = result.user.admin;
        this.support = result.user.support;
        this.aio = result.user.aio;
        // this.aio = true;

        const disc = this.whopUser.user.socials.find(x => x.service == 'discord');
        if (disc != undefined) {
          this.discord = true;
          this.discord_username = disc.username;
          this.discord_id = disc.account_id;
        }

        this.display_name = this.username
        if (this.discord_username != "") {
          this.display_name = this.discord_username
        }

        if (result.user.avatar) {
          this.avatar = result.user.avatar;
        } else {
          this.avatar = `https://api.dicebear.com/5.x/initials/svg?seed=${this.display_name}&backgroundColor=119579`
        }

        this._router.navigate(['/task'])
      }
    })
  }

  async signOut() {
    this.whopUser = undefined;
    this.valid = false;
    this.admin = false;
    this.support = false;
    this.authenticated = false;
    this.id = undefined;
    this.username = undefined;
    this.avatar = undefined;
    this.discord = undefined;
    this.discord_username = undefined;
    this.discord_id = undefined;
  }

}
