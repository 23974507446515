<div class="flex flex-col flex-auto min-w-0">

    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">

        <!-- CONTENT GOES HERE -->
        <h1 class="text-xl">{{status}} Account</h1>

        <form class="flex flex-col mt-4 px-8 pt-10 bg-card shadow rounded overflow-hidden" [formGroup]="form"
            #ngForm="ngForm">
            <fuse-alert class="mb-8" *ngIf="alert" [type]="alert.type">
                {{alert.message}}
            </fuse-alert>
            <mat-form-field *ngFor="let control of form.controls | keyvalue">
                <mat-label>{{control.key}}</mat-label>
                <input matInput [formControl]="control.value">
            </mat-form-field>
            <div class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5 bg-gray-50 dark:bg-gray-700">
                <button mat-button [color]="'accent'" [disabled]="form.pristine || form.untouched"
                    (click)="clearForm()">
                    Clear
                </button>
                <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="form.pristine || form.invalid"
                    (click)="sendForm()">
                    Save
                </button>
            </div>
        </form>
    </div>

</div>